// Ad Injection modifications on the model hub
window.vm.afterLoad(() => {
  // Get the promo link, video element and colour selector
  const videoElement = document.querySelector('.js-carousel-scroller video');
  const promoLink = document.getElementsByClassName('promotion__link');
  const colourSelector = document.getElementsByClassName('js-vehicle-colour-selector');
  const modelHubContent =
    document.querySelector('#js-modelHubContainer') ||
    document.querySelector('.js-model-hub-container');
  const jsRange = document.querySelector('.js-range');
  // Exclude class - if present then don't hide element
  const excludeElement = 'js-exclude';

  // If the colour selctor in present, hide it
  if (colourSelector && colourSelector.length > 0) {
    if (!colourSelector[0].classList.contains(`${excludeElement}`)) {
      colourSelector[0].style.display = 'none';
    }
  }

  // Hide video if in ad injection
  if (videoElement) {
    videoElement.style.display = 'none';
  }

  if (modelHubContent) {
    if (jsRange) {
      jsRange.classList.remove('hide__default');
    }
  }

  // If the promotion link is present
  if (promoLink && typeof promoLink !== 'undefined') {
    // Build the carousel element and wrap each promotion link
    Array.prototype.forEach.call(promoLink, (link) => {
      const linkContainer = document.createElement('div');

      linkContainer.classList.add(
        'carousel-scroller__slide',
        'carousel-scroller__slide--wide',
        'promotion-container',
      );
      link.parentNode.insertBefore(linkContainer, link);
      linkContainer.appendChild(link);
    });

    // Initialise the block nav scroller JS
    if (typeof promoLink[0] !== 'undefined') {
      window.initBlockScroller(promoLink[0].closest('.block-nav-scroller'));
    }
  }
});
